import React from "react";

const AbousUs = () => {
  return <div className="container py-10"><div>
  <h2 className="text-4xl underline text-primary">About Us</h2>
    <p>Your one and only online wholesale pharmacy!
Always Free delivery for regular customers</p>
  </div></div>;
};

export default AbousUs;
